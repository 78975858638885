<template>
  <v-list-item-group
    :value="currentFileId"
    mandatory
  >
    <v-list-group
      v-for="(files, entity, id) in entities"
      :key="id"
      no-action
      :value="id === 0"
      @click="fileChange(null)"
    >
      <template v-slot:activator>
        <v-list-item-content
          v-if="entity"
          class="entity"
        >
          <v-list-item-title>
            <div class="d-flex flex-row align-center">
              <v-icon class="mr-2">{{$icon(entitiesDetails[entity].icon)}}</v-icon>
              <span>{{$t(entitiesDetails[entity].displayName)}}</span>
            </div>
          </v-list-item-title>
          <div class="d-flex flex-column pl-8">
            <span class="caption">{{files.length}} {{$t('t.Files')}}</span>
            <div class="d-flex flex-wrap">
              <span
                class="caption mr-2"
                v-for="(count, type) in getEntitySummary(entity)"
                :key="`summary_${id}_${type}`"
              >
                {{type}}: {{count}}
              </span>
            </div>
          </div>
        </v-list-item-content>
        <v-list-item-content v-else>
          <v-list-item-title>
            {{$t('t.GeneralImportEntity')}}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon
          :class="{'mr-n6': !entity.warningCount}"
          class="ml-1"
        >
          <div
            v-if="errorCount(files)"
            class="d-flex align-center caption ml-4 "
          >
            <v-icon
              small
              color="error"
            >{{$icon('i.AlertCircleOutline')}}</v-icon>
            <span class="ml-1 error--text">{{errorCount(files)}}</span>
          </div>
          <div
            v-if="warningCount(files)"
            class="d-flex align-center caption ml-2"
          >
            <v-icon
              small
              color="warning"
            >{{$icon('i.AlertCircleOutline')}}</v-icon>
            <span class="ml-1 warning--text">{{warningCount(files)}}</span>
          </div>
        </v-list-item-icon>
      </template>
      <v-list-item
        dense
        v-for="file in files"
        :key="file.fileId"
        :value="file.fileId"
        class="pl-12"
        @click="fileChange(file)"
      >
        <v-list-item-content v-if="file.name">
          <v-list-item-title>
            <div class="d-flex flex-column">
              <span class="text-adapt">{{file.name}}</span>
              <div class="d-flex flex-row caption">
                <template v-if="file.lineInError > 0">
                  <span class="mr-1 error--text">{{file.lineInError}}</span>
                  <span class="mr-1">/ {{file.lineCount}} {{$t('t.ErrorLines')}}</span>
                </template>
                <template v-else>
                  <span>{{file.lineCount}} {{$t('t.Lines')}}</span>
                </template>
              </div>
            </div>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-content v-else>
          <v-list-item-title>
            {{$t('t.GeneralImportErrors')}}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon>
          <div
            v-if="file.errorCount"
            class="d-flex align-center caption"
          >
            <v-icon
              small
              color="error"
            >{{$icon('i.AlertCircleOutline')}}</v-icon>
            <span class="ml-1 error--text">{{file.errorCount}}</span>
          </div>
          <div
            v-if="file.warningCount"
            class="d-flex align-center caption ml-2"
          >
            <v-icon
              small
              color="warning"
            >{{$icon('i.AlertCircleOutline')}}</v-icon>
            <span class="ml-1 warning--text">{{file.warningCount}}</span>
          </div>
        </v-list-item-icon>
      </v-list-item>
    </v-list-group>
  </v-list-item-group>
</template>

<script>
export default {
  data () {
    return {
      summaries: {}
    }
  },
  computed: {
    entitiesDetails () {
      return {
        users: {
          displayName: 't.InternalContacts',
          icon: 'i.InternalContacts',
          type: 'InternalContacts'
        },
        accounts: {
          displayName: 't.Accounts',
          icon: 'i.Accounts',
          type: 'Accounts'
        },
        'account-contacts': {
          displayName: 't.AccountContacts',
          icon: 'i.AccountContacts',
          type: 'AccountContacts'
        },
        'unapplied-cashes': {
          displayName: 't.UnappliedCashItems',
          icon: 'i.UnappliedCashItems',
          type: 'UnappliedCashItems'
        },
        invoices: {
          displayName: 't.Invoices',
          icon: 'i.Invoices',
          type: 'Invoices'
        },
        'work-in-progress-items': {
          displayName: 't.WorkInProgressItems',
          icon: 'i.WorkInProgressItems',
          type: 'WorkInProgressItems'
        },
        disputes: {
          displayName: 't.Disputes',
          icon: 'i.Disputes',
          type: 'Disputes'
        }
      }
    }
  },
  methods: {
    getEntitySummary (entityName) {
      return this.summaries[this.entitiesDetails[entityName].type]
    },
    warningCount (files) {
      return files.reduce((acc, file) => acc + file.warningCount, 0)
    },
    errorCount (files) {
      return files.reduce((acc, file) => acc + file.errorCount, 0)
    },
    fileChange (file) {
      if (file?.fileId !== this.currentFileId) {
        this.$emit('file-changed', file)
      } else {
        this.$emit('file-changed', null)
      }
    }
  },
  watch: {
    entitiesSummary: {
      immediate: true,
      handler (entities) {
        const summaries = {}
        const statuses = [
          { code: 'Added', translation: 't.Added' },
          { code: 'Clotured', translation: 't.Closed' },
          { code: 'Linked', translation: 't.Linked' },
          { code: 'Updated', translation: 't.Updated' }
        ]

        for (const key in entities) {
          const status = statuses.find(s => key.endsWith(s.code))
          const keyName = key.replace(new RegExp(`${status.code}$`), '')
          if (!summaries[keyName]) {
            summaries[keyName] = {}
          }
          summaries[keyName][this.$t(status.translation)] = entities[key]
        }

        this.summaries = summaries
      }
    }
  },
  props: {
    entitiesSummary: Object,
    entities: Object,
    currentFileId: Number
  }
}
</script>

<style lang="stylus" scoped>
.entity
  min-width 200px
</style>
